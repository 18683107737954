.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  overflow: hidden;

  button {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 20px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;

    &:hover {
      background: #efd0ff;
    }

    &:disabled {
      background: #dfdfdf;
      cursor: not-allowed;
    }
  }
}
