.cart {
  display: flex;
  justify-content: center;
  padding: 60px 10px;
}

.content {
  width: 100%;
  max-width: 1300px;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(#000, 0.06);
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fff;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;

  h1 {
  }

  h3 {
  }
}

.action {
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(#000, 0.08);
  transition: all 0.2s linear;
  border: none;
  outline: none;
  cursor: pointer;
  height: 32px;

  &_back {
    margin-left: auto;
    border: 1px solid #fff;

    &:hover {
      box-shadow: 0 0 5px rgba(#000, 0.1);
      border: 1px solid #000;
    }
  }

  &_clear {
    background: #b11c1c;
    color: #fff;
  }
}

.table_head {
  display: grid;
  grid-template-columns: 150px repeat(4, 1fr) 50px;
  border-radius: 5px;
  overflow: hidden;

  div {
    text-align: center;
  }
}

.items {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 500px;
  overflow-y: auto;
  padding: 10px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;

  .order {
    padding: 10px 15px;
    border-radius: 5px;
    border: none;
    outline: none;
    background: #fff;
    box-shadow: 0 0 10px rgba(#000, 0.08);
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    background: #efd0ff;
    transition: all 0.2s linear;
    text-decoration: none;

    &:hover {
      background: darken(#efd0ff, 5);
      box-shadow: 0 0 5px rgba(#000, 0.2);
    }
  }
}
