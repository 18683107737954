.socials {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 10px;
  // border: 1px solid rgba(#000, 0.05);
  border-radius: 5px;
  width: max-content;
  background: #fff;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.hor {
  flex-direction: row;
}

.ver {
  flex-direction: column;
}
