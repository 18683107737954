.party {
  padding: 40px 20px;
  display: flex;
  justify-content: center;
}

.content {
  width: 100%;
  max-width: 1300px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(#000, 0.08);
  border-radius: 5px;
  background: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
  }

  @media screen and (max-width: 768px) {
    padding: 20px 5px;
  }
}

.img {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.text {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px 20px;

  @media screen and (max-width: 768px) {
    padding: 20px 5px;
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 10px;

  h1 {
    font-size: 28px;
    text-align: center;
  }

  p {
    font-size: 18px;
    color: rgba(#000, 1);

    &.notice {
      font-style: italic;
      color: rgba(#000, 0.7);
      font-weight: 400;
      font-size: 12px;
      text-align: right;
    }
  }

  ul {
    list-style-position: inside;

    li {
      font-size: 20px;
    }
  }
}

.actions {
  grid-column-start: 1;
  grid-column-end: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  padding: 10px;

  p {
    font-size: 24px;
    font-weight: 600;
  }
}
