.delivery {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 80px 10px;
}

.container {
  width: 100%;
  max-width: 1300px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(#000, 0.04);
}

.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h1 {
    font-size: 30px;
    font-weight: 700;

    @media screen and (max-width: 991px) {
      font-size: 22px;
    }
  }

  &_cart {
    display: flex;
    align-items: center;
    gap: 5px;
    text-decoration: none;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(#000, 0.08);
    background: #efd0ff;
    transition: all 0.2s linear;

    &:hover {
      background: darken(#efd0ff, 10);
      box-shadow: 0 0 5px rgba(#000, 0.1);
    }
  }
}

.filters {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  width: 100%;
  padding: 10px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.tabs {
  display: flex;
  flex-wrap: wrap;
  list-style: none;

  @media screen and (max-width: 768px) {
    gap: 5px;
  }

  &_item {
    padding: 10px 15px;
    border: 1px solid rgba(#000, 0.4);
    cursor: pointer;
    transition: all 0.2s linear;

    &:hover {
      background: rgba(#000, 0.2);
    }

    &_active {
      color: #fff;
      background: rgba(#000, 0.8);

      &:hover {
        background: rgba(#000, 0.6);
      }
    }
  }
}

.sort {
  display: flex;
  align-items: center;
  gap: 5px;

  span {
    color: #000;
    font-weight: 700;
    text-decoration: underline;
    text-decoration-style: dotted;
    width: max-content;
    cursor: pointer;
  }
}

.products {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  min-height: 400px;

  @media screen and (max-width: 1100px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  @media screen and (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
}

.product {
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(#000, 0.08);
  overflow: hidden;

  &_img,
  &_info,
  &_cat,
  &_title,
  &_actions {
    width: 100%;
  }

  &_img {
    height: 220px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }
  &_info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px 10px;
  }
  &_cat {
    padding: 5px;
    border: 1px solid rgba(#000, 0.01);
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(#000, 0.05);
    width: fit-content;
    color: #b11c1c;
  }
  &_title {
    font-size: 20px;
    font-weight: 400;
  }
  &_price {
  }
  &_actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px 10px;
    margin-top: auto;
  }

  &_add {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;

    button {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      border: none;
      outline: none;
      cursor: pointer;
      font-size: 20px;

      &:hover {
        background: #efd0ff;
      }

      &:disabled {
        background: #dfdfdf;
        cursor: not-allowed;
      }
    }
  }
}

.cart {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(#000, 0.1);
  background: #fff;

  &_badge {
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: 51;
    background: #efd0ff;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 14px;
    box-shadow: 0 0 10px rgba(#000, 0.2);
  }
}
