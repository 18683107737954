.contacts {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 50px 30px 70px;

  @media only screen and (max-width: 991px) {
    padding: 50px 10px 70px;
  }
}

.content {
  width: 100%;
  max-width: 1100px;
  padding: 80px 20px;
  background: #fff;
  border: 1px solid rgba(#5688d5, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;

  @media only screen and (max-width: 991px) {
    padding: 30px 10px;
    gap: 30px;
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    text-align: center;
    font-weight: 400;
    font-size: 32px;
  }
}

.info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;

  @media only screen and (max-width: 991px) {
    grid-template-columns: 1fr;
  }

  .schema {
    width: 100%;

    iframe {
      width: 100%;
      height: 300px;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    gap: 10px;

    p {
      font-size: 18px;
    }
  }

  .actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
  }
}
