.layout {
}

.main {
  background-image: url(../../assets/img/bg.png);
  background-size: 100%;
  background-position: center;
  background-repeat: repeat-y;
  width: 100%;
  min-height: 100vh;
  padding: 60px 0 0;
}
