.events {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 50px 30px 70px;

  @media only screen and (max-width: 991px) {
    padding: 50px 10px 70px;
  }
}

.content {
  width: 100%;
  max-width: 1100px;
  padding: 20px;
  background: #fff;
  border: 1px solid rgba(#5688d5, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @media only screen and (max-width: 991px) {
    padding: 30px 10px;
  }
}

.top {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  border-bottom: 1px solid rgba(#5689d5, 0.2);

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    text-align: center;
    color: #535353;
    font-weight: 400;
    font-size: 34px;
    font-family: serif;

    @media only screen and (max-width: 450px) {
      font-size: 24px;
    }
  }
}

.img {
  width: 100%;
  height: auto;
  max-height: 380px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
}

.text {
  display: flex;
  flex-direction: column;
  gap: 15px;

  h4 {
    font-size: 20px;
  }

  ul {
    list-style-position: inside;

    li {
      font-size: 16px;
      font-style: italic;
      color: rgba(#000, 0.7);
      margin-top: 5px;
    }
  }

  p {
    font-size: 16px;

    &.notice {
      font-size: 15px;
    }

    a {
      font-weight: 700;
    }
  }
}

.actions {
  width: 100%;
  text-align: center;
  padding: 20px;
}
