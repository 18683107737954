.product {
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(#000, 0.08);
  overflow: hidden;

  &_img,
  &_info,
  &_cat,
  &_title,
  &_actions {
    width: 100%;
  }

  &_img {
    height: 220px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }
  }
  &_info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 5px 10px;
  }
  &_cat {
    padding: 5px;
    border: 1px solid rgba(#000, 0.01);
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(#000, 0.05);
    width: fit-content;
    color: #b11c1c;
  }
  &_title {
    font-size: 20px;
    font-weight: 400;
  }
  &_price {
  }
  &_actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px 10px;
    margin-top: auto;
  }

  &_add {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    overflow: hidden;

    button {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      border: none;
      outline: none;
      cursor: pointer;
      font-size: 20px;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;

      &:hover {
        background: #efd0ff;
      }

      &:disabled {
        background: #dfdfdf;
        cursor: not-allowed;
      }
    }
  }
}
