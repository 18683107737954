.funny {
  padding: 50px 20px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 576px) {
    padding: 50px 10px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;
  max-width: 1100px;
  overflow: hidden;
  background: #fff;
  padding: 20px 20px 50px;
  border: 1px solid rgba(#5688d5, 0.2);

  @media only screen and (max-width: 576px) {
    padding: 20px 10px 50px;
  }
}

.title {
  padding: 30px 10px 20px;
  text-align: center;

  h1 {
    font-size: 30px;
    color: #000;
  }

  p {
  }
}

.text {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;

  ul {
    list-style: none;
    padding-left: 20px;

    li {
      color: rgba(#000, 0.5);
    }
  }
}

.images {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  width: 100%;

  @media only screen and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 576px) {
    grid-template-columns: repeat(1, 1fr);
  }

  &_item {
    display: block;
    width: 100%;
    height: 200px;

    @media only screen and (max-width: 991px) {
      height: 300px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
