.card {
  width: 100%;
  max-width: 1300px;
  min-height: 50px;
  margin: 0 auto;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(#000, 0.08);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
  }
}

.img {
  width: 100%;
  overflow: hidden;

  @media screen and (max-width: 991px) {
    max-height: 350px;
  }

  img {
    display: block;
    width: 100%;
    height: auto;

    @media screen and (max-width: 991px) {
      height: 100%;
      object-fit: cover;
    }
  }
}

.info {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    li {
      padding: 5px;
      color: #b11c1c;
      font-size: 14px;
      box-shadow: 0 0 10px rgba(#000, 0.08);
      border-radius: 5px;
    }
  }
}

.title {
  width: calc(100% - 45px);
}

.description {
  padding: 5px;
  background: rgba(#000, 0.01);
  border: 1px solid rgba(#000, 0.04);
  border-radius: 5px;
}

.categories,
.weight,
.calories {
  display: flex;
  align-items: center;
  gap: 10px;

  @media screen and (max-width: 1100px) {
    align-items: flex-start;
  }

  @media screen and (max-width: 991px) {
    flex-direction: column;
  }
}

.calories {
}

.actions {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px 0 10px;

  p {
    font-size: 25px;
  }
}
