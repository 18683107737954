.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  border-top: 1px solid rgba(#5688d5, 0.2);
}

.content {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0 0 0 40px;

  @media only screen and (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0;
  }

  @media only screen and (max-width: 850px) {
    grid-template-columns: repeat(1, 1fr);
  }

  &_block {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px 20px;

    p {
      font-size: 14px;
      color: rgba(#000, 0.4);
      text-align: justify;
    }
  }

  &_menu {
    background: rgba(#5688d5, 0.07);
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media only screen and (max-width: 450px) {
      grid-template-columns: 1fr;
    }

    .menu_title {
      grid-column-start: 1;
      grid-column-end: 3;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      @media only screen and (max-width: 450px) {
        grid-column-start: unset;
        grid-column-end: unset;
      }

      h3 {
        text-transform: uppercase;
        font-size: 18px;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 10px;
      list-style: none;

      li {
        transition: all 0.2s linear;

        &:hover {
          background: rgba(#000, 0.4);
          color: #fff;
        }

        a {
          text-decoration: none;
          display: block;
          border-bottom: 1px solid rgba(#000, 0.4);
          padding: 5px;
        }
      }
    }
  }

  &_map {
    padding: 0;

    @media only screen and (max-width: 1300px) {
      grid-column-start: 1;
      grid-column-end: 3;
      height: 300px;
    }

    @media only screen and (max-width: 850px) {
      grid-column-start: unset;
      grid-column-end: unset;
    }
  }

  .contacts {
    display: flex;
    align-items: center;
    gap: 20px;

    @media only screen and (max-width: 400px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.logo {
  width: 280px;

  @media only screen and (max-width: 450px) {
    width: 200px;
  }
}

.copy {
  background: rgba(#5688d5, 0.07);
  padding: 5px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;

  span {
    font-size: 16px;
    color: rgba(#555555, 0.7);
  }
  p {
    font-size: 12px;
    color: rgba(#555555, 0.5);
  }
}
