.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 991px) {
    position: fixed;
    width: max-content;
    min-width: 350px;
    height: 100%;
    left: -100%;
    top: 50px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s linear;
    background: rgba(#ffffff, 1);
    box-shadow: 0 0 10px rgba(#000, 0.5);
    align-items: flex-start;
    justify-content: flex-start;
    padding: 40px 15px;
    border-radius: 0 10px 10px 0;
    overflow-y: auto;

    &.show {
      left: 0;
      opacity: 1;
      visibility: visible;
    }
  }

  @media only screen and (max-width: 400px) {
    min-width: unset;
    width: 100%;
  }
}

$dark: #212121;
$light: #efefef;
$color: #b3e5fc;

.burger {
  display: none;
  position: relative;
  width: 30px;
  height: 30px;
  transition-duration: 0.5s;
  $self: &;

  @media only screen and (max-width: 991px) {
    display: block;
  }

  &_icon {
    transition-duration: 0.5s;
    position: absolute;
    height: 4px;
    width: 15px;
    top: 15px;
    background-color: $dark;

    &:before {
      transition-duration: 0.5s;
      position: absolute;
      width: 15px;
      height: 4px;
      background-color: $dark;
      content: '';
      top: -10px;
    }

    &:after {
      transition-duration: 0.5s;
      position: absolute;
      width: 15px;
      height: 4px;
      background-color: $dark;
      content: '';
      top: 10px;
    }

    &_left {
      left: 0px;
    }

    &_right {
      left: 15px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &_opened {
    #{$self}_icon_left {
      transition-duration: 0.5s;
      background: transparent;

      &:before {
        transform: rotateZ(45deg) scaleX(1.4) translate(2px, 2px);
      }

      &:after {
        transform: rotateZ(-45deg) scaleX(1.4) translate(2px, -2px);
      }
    }

    #{$self}_icon_right {
      transition-duration: 0.5s;
      background: transparent;

      &:before {
        transform: rotateZ(-45deg) scaleX(1.4) translate(-2px, 2px);
      }

      &:after {
        transform: rotateZ(45deg) scaleX(1.4) translate(-2px, -2px);
      }
    }
  }
  &:hover {
    cursor: pointer;
  }
}

.menu {
  $self: &;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;

  @media only screen and (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  &_item {
    height: 100%;
    transition: all 0.2s linear;
    $item: &;

    @media only screen and (max-width: 991px) {
      border-bottom: 1px solid rgba(#000, 0.2);
      width: 100%;
      font-size: 22px;
    }

    .icon {
      width: 10px;
      height: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
      }

      &_arrow {
        @media only screen and (max-width: 991px) {
          display: none;
        }
      }

      &_away {
      }
    }

    &:hover {
      background: rgba(#659bff, 0.05);

      #{$self}_sub {
        opacity: 1;
        visibility: visible;
      }

      .icon {
        &_arrow {
          transform: scale(1, -1);
        }
      }
    }

    &_parent {
      position: relative;
    }
  }

  &_link,
  &_nonlink {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 15px;
    text-decoration: none;
    cursor: pointer;
    color: #000;

    @media only screen and (max-width: 991px) {
      padding: 7px 10px;
    }
  }

  &_sub {
    position: absolute;
    top: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: max-content;
    opacity: 0;
    visibility: hidden;
    transition: all 0.1s linear;
    background: rgba(#fff, 1);

    @media only screen and (max-width: 991px) {
      position: relative;
      top: unset;
      left: unset;
      visibility: visible;
      opacity: 1;
      background: none;
      padding-left: 10px;
      gap: 5px;
    }

    #{$self}_item {
      width: 100%;

      @media only screen and (max-width: 991px) {
        font-size: 16px;
      }

      &:hover {
        background: rgba(#659bff, 1);
        color: #fff;

        #{$self}_link {
          color: inherit;
        }
      }
    }

    #{$self}_link {
      padding: 5px 10px;
    }
  }
}
