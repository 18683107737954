.share {
  width: 100%;
  padding: 40px 20px 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 991px) {
    padding: 40px 10px 50px;
  }
}

.content {
  width: 100%;
  max-width: 1100px;
  background: #fff;
  padding: 30px 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  border: 1px solid rgba(#5689d5, 0.2);

  @media only screen and (max-width: 991px) {
    padding: 30px 15px;
  }
}

.top {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  border-bottom: 1px solid rgba(#5689d5, 0.2);

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    text-align: center;
    color: #535353;
    font-weight: 400;
    font-size: 34px;
    font-family: serif;

    @media only screen and (max-width: 450px) {
      font-size: 24px;
    }
  }
}

.img {
  width: 100%;
  height: auto;
  max-height: 380px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.description,
.options,
.conditions,
.actions {
  width: 100%;

  ul {
    list-style-position: inside;
  }

  p {
    font-size: 15px;
    margin-top: 8px;
  }

  h4 {
    margin-bottom: 10px;
  }
}

.actions {
  text-align: center;
  &.notice {
    font-size: 14px;
    color: rgba(#000, 0.6);
    font-style: italic;
    margin-bottom: 5px;
  }
}
