.card {
  display: grid;
  grid-template-columns: 150px repeat(4, 1fr) 50px;
  box-shadow: 0 0 10px rgba(#000, 0.08);
  border-radius: 5px;
}

.img {
  width: 100%;
  height: 100%;
  border-radius: 5px 0 0 5px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}

.title {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  span {
    padding: 5px;
    border: 1px solid rgba(0, 0, 0, 0.01);
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    width: fit-content;
    color: #b11c1c;
    font-size: 14px;
  }
}

.price,
.actions,
.total,
.remove {
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove {
  button {
    background: #fff;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    color: #b11c1c;
    border-radius: 50%;
    border: 1px solid #b11c1c;
    padding: 5px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}
