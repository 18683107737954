.logo {
  padding: 10px 0;
  width: 80px;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  img {
    display: block;
    width: 100%;
    height: 100%;
  }
}
