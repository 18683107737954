*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  font-size: 16px;
  color: #2b2b2b;
  font-family: 'Nunito Sans', sans-serif;
  scroll-behavior: smooth;

  &.show_modal {
    overflow-y: hidden;
  }
}

a {
  color: #2b2b2b;
  text-decoration: underline;

  &:visited {
    color: inherit;
  }
}

#modal-root {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
