.loyalty {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 50px 30px 70px;

  @media only screen and (max-width: 991px) {
    padding: 50px 10px 60px;
  }
}

.content {
  width: 100%;
  max-width: 1100px;
  padding: 20px;
  background: #fff;
  border: 1px solid rgba(#5688d5, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  overflow: hidden;

  @media only screen and (max-width: 991px) {
    padding: 20px 10px;
  }

  @media only screen and (max-width: 550px) {
    overflow-x: scroll;
  }
}

.title {
  display: flex;
  flex-direction: column;
  gap: 10px;

  h1 {
    text-align: center;
    margin: 0 auto;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-left: 1px solid rgba(#000, 0.3);
  border-top: 1px solid rgba(#000, 0.3);
  width: 100%;

  @media only screen and (max-width: 550px) {
    width: max-content;
    align-self: flex-start;
  }

  &_item {
    border-right: 1px solid rgba(#000, 0.3);
    border-bottom: 1px solid rgba(#000, 0.3);
    padding: 5px 10px;

    @media only screen and (max-width: 700px) {
      font-size: 14px;
    }

    &_title {
      font-weight: 700;
    }
  }
}

.registration {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .form {
    width: 100%;

    iframe {
      height: 1000px;

      @media only screen and (max-width: 430px) {
        height: 1170px;
      }
    }
  }
}
