.shares {
  width: 100%;
  padding: 40px 20px 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 991px) {
    padding: 40px 10px 50px;
  }
}

.content {
  width: 100%;
  max-width: 1100px;
  background: #fff;
  padding: 30px 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  border: 1px solid rgba(#5689d5, 0.2);

  @media only screen and (max-width: 991px) {
    padding: 30px 10px;
  }
}

.title {
}

.items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  width: 100%;

  @media only screen and (max-width: 991px) {
    gap: 20px;
  }

  @media only screen and (max-width: 576px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.item {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  border: 1px solid rgba(#5689d5, 0.1);
  border-radius: 10px;
  overflow: hidden;
  padding-bottom: 15px;

  &_img {
    width: 100%;
    height: 400px;

    @media only screen and (max-width: 768px) {
      height: 270px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &_title,
  &_actions {
    padding: 0 15px;
  }

  &_title {
    h4 {
      text-align: center;
      font-size: 18px;
    }
  }

  &_actions {
    margin-top: auto;
  }
}
