.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#000000, 0.5);
  z-index: 999;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background: transparent;
  width: clamp(300px, 85%, 1300px);

  @media (max-height: 768px) {
    top: 60px;
    transform: translate(-50%, 0);
  }
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;

  span {
    font-size: 25px;
    font-weight: 700;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ff0000;
    border-radius: 50%;
    background: #fff;
    color: #ff0000;
    line-height: 1;
    transition: all 0.2s linear;
    box-shadow: 0 0 10px rgba(#000, 0.5);

    &:hover {
      color: #fff;
      border: 1px solid #fff;
      background: #ff0000;
    }
  }
}

.body {
  max-height: calc(100vh - 120px);
  overflow-y: auto;
}
