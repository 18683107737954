.intro {
  padding-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;

  .swiper {
    width: 100%;
    height: 40vh;

    @media only screen and (max-width: 600px) {
      height: 300px;
    }

    :global(.swiper-wrapper) {
      // width: 100%;
    }

    .slide {
      &_img {
        width: 100%;
        height: 100%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  &_content {
    position: relative;
    width: calc(100% - 200px);
    z-index: 5;
    padding: 40px 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    gap: 10px;
    background: linear-gradient(90deg, #00000000, #000000e6, #00000000);
    color: #fff;

    @media only screen and (max-width: 1100px) {
      width: calc(100% - 50px);
    }

    @media only screen and (max-width: 768px) {
      width: 100%;
      padding: 40px 20px;
      background: rgba(darken(#5688d5, 10), 0.7);
    }

    .decorator {
      display: block;
      width: 250px;
      height: 3px;
      background: #edf2f4;
      margin: 10px 0 20px;
    }

    p {
      font-size: 16px;

      a {
        color: #fff;
        text-transform: uppercase;
        text-decoration: underline;
      }
    }

    h1 {
      color: #edf2f4;
      font-size: 38px;
      line-height: 1.3;

      span {
        color: #dde7ea;
        text-transform: uppercase;
        font-size: 48px;
      }
    }

    h2 {
      font-size: 22px;
    }
  }

  &_actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    padding: 20px 10px;

    @media only screen and (max-width: 576px) {
      flex-direction: column;
    }
  }

  .trigger_3d {
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 4;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: 10px;
    border: 1px solid #000;
    cursor: pointer;

    img {
      width: 100%;
    }
  }
}

.about {
  width: 100%;
  padding: 70px 20px 40px;
  display: flex;
  justify-content: center;

  &_content {
    display: flex;
    gap: 30px;
    width: 100%;
    max-width: 1124px;

    @media only screen and (max-width: 991px) {
      flex-direction: column-reverse;
    }
  }

  &_gallery {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 400px);
    grid-template-rows: auto auto;
    gap: 1rem;

    @media only screen and (max-width: 991px) {
      grid-template-columns: repeat(1, 1fr);
    }

    @media only screen and (max-width: 450px) {
      grid-template-columns: repeat(1, 1fr);
    }

    &_item {
      display: block;
      width: 100%;
      height: auto;

      @media only screen and (max-width: 991px) {
        max-height: 300px;
      }

      @media only screen and (max-width: 500px) {
        max-height: unset;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &_text {
    h2 {
      font-size: 32px;
      color: #515151;
      margin-bottom: 10px;
    }

    p {
      margin-top: 10px;
      font-size: 18px;
      color: #2b2b2b;
    }
  }
}

.kudago {
  width: 100%;
  padding: 30px 20px;
  display: flex;
  justify-content: center;
  padding: 50px 15px;

  &_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    width: 100%;
    max-width: 950px;

    @media only screen and (max-width: 991px) {
    }
  }

  &_text {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    h2 {
      font-size: 32px;
    }

    p {
      font-size: 18px;

      &.notice {
        font-size: 15px;
        color: rgba(#000, 0.7);
      }
    }
  }

  &_img {
    &_item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 350px;
      height: 490px;
      border-radius: 1rem;
      overflow: hidden;
      box-shadow: 0 0 1rem 0.5rem rgba(0, 0, 0, 0.4);

      @media only screen and (max-width: 576px) {
        width: 100%;
        height: auto;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.tour_3d {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 80px 10px 40px;

  h2 {
    font-size: 24px;
    text-align: center;
  }

  iframe {
    width: 100%;
    max-width: 1320px;
    height: 760px;

    @media only screen and (max-width: 1100px) {
      height: 580px;
    }

    @media only screen and (max-width: 700px) {
      height: 430px;
    }
  }
}

.video {
  width: 100%;
  padding: 70px 20px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  video {
    width: 100%;
    max-width: 900px;
  }

  h2 {
    font-size: 22px;
    color: #515151;
    text-align: center;
  }
}

.callBack {
  padding: 30px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;
  border-radius: 5px;

  @media (max-width: 450px), (max-height: 880px) {
    padding: 30px 20px 60px;
  }
}
