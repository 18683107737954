.error {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 70px 10px;
}

.content {
  width: 100%;
  max-width: 800px;
  padding: 30px;
  box-shadow: 0 0 10px rgba(#000, 0.08);
  border-radius: 5px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  text-align: center;

  @media only screen and (max-width: 576px) {
    padding: 15px;
  }

  h1 {
    font-size: 120px;
    font-weight: 800;
    color: rgba(#af5c5c, 1);

    @media only screen and (max-width: 576px) {
      font-size: 80px;
    }
  }

  h4 {
    font-size: 40px;

    @media only screen and (max-width: 576px) {
      font-size: 30px;
    }
  }

  p {
    font-size: 24px;

    @media only screen and (max-width: 576px) {
      font-size: 18px;
    }
  }
}

.goback {
  display: block;
  padding: 5px 15px;
  text-decoration: none;
  font-size: 20px;
  text-transform: uppercase;
  border-radius: 5px;
  border: 1px solid rgba(#000, 0.1);
  transition: all 0.2s linear;

  &:hover {
    color: #af5c5c;
    border-color: #af5c5c;
  }
}
