.button {
  height: 40px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  border: none;
  text-transform: uppercase;
  padding: 10px 25px;
  border-radius: 5px;
  text-decoration: none;
}

.prime {
  color: #231942;
  background: #fff;
  box-shadow: inset 2px 2px 2px 0 rgba(255, 255, 255, 0.5), 7px 7px 20px 0 rgba(0, 0, 0, 0.1),
    4px 4px 5px 0 rgba(0, 0, 0, 0.1);
  background: linear-gradient(0deg, #fdfdfe 0, #bed0d7 100%);
  overflow: hidden;

  &:hover {
    color: #06040b;
    opacity: 0.7;
  }
}

.outlined {
  border: 2px solid #edf2f4;
  color: #edf2f4;
}

.link {
}
